









import { Component, Vue } from 'vue-property-decorator'
import NavMenu from '@/partials/components/NavMenu.vue'

import PERMISSIONS from '@/helpers/permissions'

@Component({
  components: {
    NavMenu
  }
})
export default class QuestionsMenuLayout extends Vue {
  availableRoutes: AvailableRoute[] = []
  created () {
    this.availableRoutes = [
      {
        label: 'Realizadas',
        path: '/exams/finished',
        permission: PERMISSIONS.EXAM.READ
      },
      {
        label: 'Agendadas',
        path: '/exams/scheduled',
        permission: PERMISSIONS.EXAMSCHEDULE.READ
      },
      {
        label: 'Modelos',
        path: '/exams/models',
        permission: PERMISSIONS.EXAM.READ
      },
      {
        label: 'Rascunhos',
        path: '/exams/drafts',
        permission: PERMISSIONS.EXAMDRAFT.READ
      }
    ]
  }
}
